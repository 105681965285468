<template>
  <div>
    <v-card id="commission-receipt-list">
      <v-card-text class="row pb-0 pr-0">
        <div class="col-sm-3 col-12 pb-sm-8 pb-1 pl-2 pr-1 pt-sm-0">
          <!-- Date range -->
          <v-menu
            v-model="isDateMenuOpen"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :append-icon="icons.mdiCalendar"
                :value="dateRangeText"
                readonly
                dense
                outlined
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateRange"
              color="primary"
              :first-day-of-week="1"
              range
            >
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                block
                @click="dateRangeChange()"
              >
                {{ t('Set Dates') }}
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>

        <div class="col-sm-3 offset-sm-6 px-2 pt-sm-0 col-12 text-right">
          <p class="mb-0">Unclaimed: {{ commission.unclaimed }}</p>
          <p>Claimed: {{ commission.claimed }}</p>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="tablePagination.per"
                :items="listLengthOptions"
                single-line
                outlined
                dense
                hide-details
                class="receipt-list-row-selection"
                @input="listLengthChange()"
              ></v-select>
              <div class="pl-3">
                {{ t('Rows') }}
              </div>
            </div>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              @input="fetchCommission()"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="receipts"
        :items-per-page="100"
        :loading="loading"
        mobile-breakpoint="0"
        hide-default-footer
        :disable-sort="true"
        class="text-no-wrap"
      >
        <!-- Actions -->
        <template #[`item.actions`]="{item}">
          <div class="d-flex align-center">
            <!-- Edit -->
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  :to="{
                    name: 'apps-receipt-edit',
                    params: { id: item.id }
                  }"
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </div>
        </template>

        <!-- Receipt Number -->
        <template #[`item.receipt_number`]="{item}">
          <router-link
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'apps-receipt-preview', params: { id: item.id } }"
          >
            {{ item.receipt_number }}
          </router-link>
        </template>
      </v-data-table>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="tablePagination.per"
                :items="listLengthOptions"
                single-line
                outlined
                dense
                hide-details
                class="receipt-list-row-selection"
                @input="listLengthChange()"
              ></v-select>
              <div class="pl-3">
                {{ t('Rows') }}
              </div>
            </div>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              @input="fetchCommission()"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div class="text-right">
      <v-btn
        color="primary"
        :loading="loadingClaim"
        :disabled="loadingClaim || commission.unclaimed === '$0.00'"
        :block="$vuetify.breakpoint.xsOnly"
        class="receipt-button my-5 mr-2"
        @click="isClaimDialogVisible = true"
      >
        {{ t('Claim') }}
      </v-btn>
      <v-btn
        color="primary"
        :loading="loadingPrint"
        :disabled="loadingPrint"
        :block="$vuetify.breakpoint.xsOnly"
        class="receipt-button my-5"
        @click="printCommissionReport()"
      >
        <v-icon
          v-if="!loadingPrint"
          size="18"
          class="me-2"
        >
          {{ icons.mdiPrinter }}
        </v-icon>
        <span>{{ t('Print Report') }}</span>
      </v-btn>
    </div>

    <v-card id="commission-claims-list">
      <v-card-text class="text-h6">
        Claimed Commission
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="claimsTablePagination.per"
                :items="claimsListLengthOptions"
                single-line
                outlined
                dense
                hide-details
                class="receipt-list-row-selection"
                @input="claimsListLengthChange()"
              ></v-select>
              <div class="pl-3">
                {{ t('Rows') }}
              </div>
            </div>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="claimsTablePagination.current_page"
              total-visible="6"
              :length="claimsTablePagination.total_page"
              @input="fetchClaims()"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- table -->
      <v-data-table
        :headers="claimsTableColumns"
        :items="claims"
        :items-per-page="100"
        :loading="loadingClaims"
        mobile-breakpoint="0"
        hide-default-footer
        :disable-sort="true"
        class="text-no-wrap"
      ></v-data-table>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="claimsTablePagination.per"
                :items="claimsListLengthOptions"
                single-line
                outlined
                dense
                hide-details
                class="receipt-list-row-selection"
                @input="claimsListLengthChange()"
              ></v-select>
              <div class="pl-3">
                {{ t('Rows') }}
              </div>
            </div>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="claimsTablePagination.current_page"
              total-visible="6"
              :length="claimsTablePagination.total_page"
              @input="fetchClaims()"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Confirmation dialog -->
    <v-dialog
      v-model="isClaimDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title class="d-flex align-center mv-4">
          Claim commission
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isClaimDialogVisible = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          This action will claim all available commission on {{ dateRangeText }}
        </v-card-text>

        <v-card-actions class="mt-2">
          <v-btn
            color="primary"
            dark
            block
            :loading="loadingClaim"
            :disabled="loadingClaim"
            class="mt-5"
            @click="claimCommission()"
          >
            Claim Commission
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiCurrencyUsd,
  mdiAlertOutline,
  mdiCalendar,
  mdiClose,
  mdiPencilOutline,
  mdiPrinter,
} from '@mdi/js'
import {
  ref,
  inject,
  computed,
  onMounted,
} from '@vue/composition-api'

export default {
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const t = inject('t')
    const format = 'pdf'

    // Table Handlers
    const tableColumns = computed(() => (
      [
        { text: t('Actions'), value: 'actions' },
        { text: t('#Receipt No.'), value: 'receipt_number' },
        { text: t('Issue Date'), value: 'issued_at' },
        { text: t('Commission Earned'), value: 'commission_amount' },
        { text: t('Claimed At'), value: 'commission_claimed' },
      ]
    ))
    const claimsTableColumns = computed(() => (
      [
        { text: t('Commission Earned'), value: 'commission_amount', align: 'center' },
        { text: t('Claimed At'), value: 'commission_claimed', align: 'center' },
      ]
    ))

    // Properties
    const [dayNow, monthNow, yearNow] = new Date().toLocaleDateString('en-SG').split('/')
    const receipts = ref([])
    const claims = ref([])
    const dateRange = ref([`${yearNow}-${monthNow}-${dayNow}`])
    const isDateMenuOpen = ref(false)
    const loading = ref(false)
    const loadingClaim = ref(false)
    const loadingClaims = ref(false)
    const loadingPrint = ref(false)
    const isClaimDialogVisible = ref(false)
    const listLengthOptions = ref([20, 50, 100])
    const claimsListLengthOptions = ref([20, 50, 100])
    const tablePagination = ref({
      current_page: 1,
      per: 20,
      total_count: 0,
      total_page: 0,
    })
    const claimsTablePagination = ref({
      current_page: 1,
      per: 20,
      total_count: 0,
      total_page: 0,
    })
    const tab = ref('')
    const tabs = computed(() => (
      [
        { title: t('Commission'), icon: mdiCurrencyUsd },
      ]
    ))
    const commission = ref({
      unclaimed: null,
      claimed: null,
    })

    // Utils
    const formatDate = date => {
      if (!date) return null
      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    }

    // Computed
    const dateRangeText = computed(() => {
      if (Date.parse(dateRange.value[0]) > Date.parse(dateRange.value[1])) {
        const [start, end] = dateRange.value
        dateRange.value[0] = end
        dateRange.value[1] = start
      }

      const startDateText = formatDate(dateRange.value[0])
      const showEndDate = dateRange.value[0] !== dateRange.value[1] && formatDate(dateRange.value[1]) !== null
      const endDateText = showEndDate ? ` - ${formatDate(dateRange.value[1])}` : ''

      return `${startDateText}${endDateText}`
    })

    // Methods
    const isLoading = () => {
      loading.value = true
      snackbarService.info('Loading your list. Please hold...')
    }
    const doneLoading = () => {
      loading.value = false
      snackbarService.success('Done loading your list!')
    }
    const fetchCommission = () => {
      isLoading()
      store
        .dispatch('fetchCommission', {
          page: tablePagination.value.current_page,
          per: tablePagination.value.per,
          start_date: dateRange.value[0],
          end_date: dateRange.value[1] ? dateRange.value[1] : dateRange.value[0],
        })
        .then(response => {
          const { data, pagination } = response.data
          receipts.value = data
          receipts.value.forEach(field => {
            Object.keys(field).forEach(key => {
              if (field[key] === null) field[key] = 'NULL'
            })
          })
          tablePagination.value = pagination
          commission.value = response.data.commission
          doneLoading()
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching commission. Please refresh!')
        })
    }
    const fetchClaims = () => {
      loadingClaims.value = true
      store
        .dispatch('fetchClaims', {
          page: claimsTablePagination.value.current_page,
          per: claimsTablePagination.value.per,
        })
        .then(response => {
          const { data, pagination } = response.data
          claims.value = data
          claims.value.forEach(field => {
            Object.keys(field).forEach(key => {
              if (field[key] === null) field[key] = 'NULL'
            })
          })
          claimsTablePagination.value = pagination
          loadingClaims.value = false
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching claims. Please refresh!')
        })
    }
    const printCommissionReport = () => {
      loadingPrint.value = true
      store
        .dispatch('printCommissionReport', {
          start_date: dateRange.value[0],
          end_date: dateRange.value[1] ? dateRange.value[1] : dateRange.value[0],
          format,
        })
        .then(response => {
          const url = window.URL.createObjectURL(response)
          const a = document.createElement('a')
          a.href = url
          a.download = `commission(${dateRangeText.value}).${format}`
          document.body.appendChild(a)
          a.click()
          a.remove()
          loadingPrint.value = false
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while printing report. Please refresh!')
        })
    }
    const claimCommission = () => {
      loadingClaim.value = true
      store
        .dispatch('claimCommission', {
          start_date: dateRange.value[0],
          end_date: dateRange.value[1] ? dateRange.value[1] : dateRange.value[0],
        })
        .then(response => {
          snackbarService.success(response.data.message)
          loadingClaim.value = false
          isClaimDialogVisible.value = false
          fetchCommission()
          fetchClaims()
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while claiming commission. Please refresh!')
        })
    }
    const dateRangeChange = () => {
      isDateMenuOpen.value = false
      fetchCommission()
    }
    const listLengthChange = () => {
      tablePagination.value.current_page = 1
      fetchCommission()
    }
    const claimsListLengthChange = () => {
      claimsTablePagination.value.current_page = 1
      fetchClaims()
    }

    // Mounted
    onMounted(() => {
      fetchCommission()
      fetchClaims()
    })

    return {
      t,
      loading,
      loadingClaim,
      loadingClaims,
      loadingPrint,
      isClaimDialogVisible,
      tableColumns,
      claimsTableColumns,
      tablePagination,
      claimsTablePagination,
      listLengthOptions,
      claimsListLengthOptions,
      listLengthChange,
      claimsListLengthChange,
      tab,
      tabs,
      fetchCommission,
      fetchClaims,
      printCommissionReport,
      claimCommission,
      commission,
      receipts,
      claims,
      dateRange,
      dateRangeText,
      dateRangeChange,
      formatDate,
      isDateMenuOpen,
      icons: {
        mdiCurrencyUsd,
        mdiAlertOutline,
        mdiCalendar,
        mdiClose,
        mdiPencilOutline,
        mdiPrinter,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
  #commission-receipt-list, #commission-claims-list {
    .receipt-list-row-selection {
      max-width: 5.3rem;
    }
  }

  .receipt-button {
    width: 251px;
  }
</style>
